import React, { Component } from 'react';
import Layout from '../components/Layout';
import { graphql } from "gatsby";
import {Helmet} from 'react-helmet';
import { FacebookShareButton,LineShareButton,TwitterShareButton } from 'react-share';
import { FacebookIcon,LineIcon,TwitterIcon } from 'react-share';
import {MdDateRange} from 'react-icons/md';

class BlogPost extends Component {
    render() {
        const { data } = this.props
        return (
            <div>
              <Helmet>
                <title>{data.markdownRemark.frontmatter.title}</title>
                <meta property="og:url" content={'www.bugyourdream.com/'+data.markdownRemark.fields.slug} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:title" content={data.markdownRemark.frontmatter.title} /> */}
                <meta property="og:description" content={data.markdownRemark.excerpt} />
                <meta property="og:image" content={'www.bugyourdream.com/'+data.markdownRemark.frontmatter.image.relativePath} />
              </Helmet>
                <Layout>
                  <div className="container" style={{maxWidth:'768px'}}>
                  <div className="row mt-3">
                      <div className="col-6">
                        <div className="p-2"><MdDateRange /> {data.markdownRemark.frontmatter.date}</div>
                      </div>
                      <div className="col-6 text-right">
                        <FacebookShareButton url={'www.bugyourdream.com/'+data.markdownRemark.fields.slug} className="p-1">
                          <FacebookIcon size={32} round={true}/>
                        </FacebookShareButton>
                        <LineShareButton url={'www.bugyourdream.com/'+data.markdownRemark.fields.slug} className="p-1">
                          <LineIcon size={32} round={true} /> 
                        </LineShareButton>
                        <TwitterShareButton url={'www.bugyourdream.com/'+data.markdownRemark.fields.slug} className="p-1">
                          <TwitterIcon size={32} round={true} /> 
                        </TwitterShareButton>
                      </div>
                      <div className="col-12">
                        <hr className="mt-0" />
                      </div>
                    </div> 
                    <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
                  </div>
                </Layout>
            </div>
        );
    }
}

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        image {
          relativePath
        }
      }
      fields{
        slug
      }
      excerpt
    }
  }
`